@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* 
font-family: 'Exo', sans-serif;
font-family: 'Jost', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
 */
:root {
  --main-color: #1113a2;
  --title-color: rgba(0, 0, 0, 0.72);
  --gray-color: #353634;
}
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  color: var(--title-color);
  font-family: "Poppins", ans-serif;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Exo", sans-serif;
  margin: 0px;
}
p {
  font-family: "Poppins", sans-serif;
  margin: 0;
}
a {
  text-decoration: none;
}
.title-color {
  color: var(--title-color) !important;
}
.gray-color {
  color: var(--gray-color);
}
.text-main {
  color: var(--main-color);
}

html {
  scroll-behavior: auto !important;
}

.font-exo {
  font-family: "Exo", sans-serif;
}
.font-jost {
  font-family: "Jost", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}
.fw-bold {
  font-weight: bold;
}
.main-content-wrapper {
  margin: 64px 20px 0px 20px;
  overflow: hidden;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 10px;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-5 {
  margin-top: 3rem;
}
.ml-0 {
  margin-left: 0px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 10px;
}
.ml-4 {
  margin-left: 1.5rem;
}
.ml-5 {
  margin-left: 3rem;
}
.mr-0 {
  margin-right: 0px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  padding-right: 1rem;
}
.mr-4 {
  margin-right: 1.5rem;
}
.mr-5 {
  margin-right: 3rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 10px;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 3rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.pt-1 {
  margin-top: 0.25rem;
}
.pt-2 {
  margin-top: 0.5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  margin-top: 1.5rem;
}
.pt-5 {
  margin-top: 3rem;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 1rem;
}
.pl-4 {
  padding-left: 1.5rem;
}
.pl-5 {
  padding-left: 3rem;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 1.5rem;
}
.pr-5 {
  padding-right: 3rem;
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

/* drawer for large device */
.swal2-container {
  z-index: 9999999 !important;
}

.activeSidebarLink {
  background: rgb(218, 228, 241) !important;
  display: block;
}

.activeSidebarLink span {
  color: rgba(0, 0, 0, 0.72) !important;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 20px !important;
}

.unActiveLink span {
  color: var(--title-color);
}

.MuiFormControl-root .MuiFormLabel-asterisk {
  color: red !important;
}
