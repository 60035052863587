.spinner-wrapper {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-remove-button {
  background-color: #0c4da2 !important;
  color: white !important;
}
.add-remove-button:hover {
  background-color: #0c4da2 !important;
  color: white !important;
}
@media only screen and (min-width: 617px) and (max-width: 1367px) {
  .text-wrap {
    width: 30ch !important;
    word-wrap: break-word !important;
    font-size: 15px;
  }
}
