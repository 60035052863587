/* sidebar */
.sidebar-links .css-5uknkm-MuiTypography-root {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
}
.company-info h2 {
  padding-bottom: 10px;
}
.company-info ul {
  margin: 0;
  padding: 0;
}
.company-info ul li {
  display: flex;
  margin-bottom: 10px;
}
.company-info ul li span {
  width: 40%;
  font-weight: 600;
  font-size: 0.9rem;
}
.company-info ul li p {
  padding-left: 10px;
  font-size: 0.9rem;
}
.company-info ul li p a {
  color: #046df7;
}

.dashboard-main-section .box-container {
  /* border-bottom: 2px solid #1113a2; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px 15px;
  border-radius: 4px;
  word-break: break-all;
}
.payment-info ul {
  margin: 0px;
  padding: 0;
}
.payment-info ul li {
  display: flex;
  margin-bottom: 10px;
}
.payment-info ul li span {
  width: 25%;
  font-weight: 400;
  font-size: 0.8rem;
  display: inline-block;
}
.payment-info ul li p {
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 600;
}

/* representative info */

.box-container {
  /* border-bottom: 2px solid #1113a2; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px 15px;
  border-radius: 4px;
  word-break: break-all;
  background-color: #ffffff;
}

.approved {
  background-color: rgba(87, 202, 34, 0.1);
  color: #57ca22;
  border-radius: 10px;
  padding: 2px 12px;
  display: inline-block;
}

.pending {
  background-color: rgba(255, 163, 25, 0.1);
  color: #ffa319;
  border-radius: 10px;
  padding: 2px 12px;
  display: inline-block;
}
.canceled {
  color: #e8efff;
  font-weight: bold;
}
.reject {
  color: red;
  font-weight: bold;
}
@media only screen and (max-width: 896px) {
  .company-info ul li span {
    width: 45%;
    margin-right: 10px;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

.bg-style {
  background: #e8efff;
  padding: 15px;
  border-radius: 10px;
}
.bg-style.one {
  background: #f3fbe8;
}
.bg-style.two {
  background: #e8efff;
}
.bg-style.three {
  background: #f3fbe8;
}
.bg-style.four {
  background: #e8efff;
}
.bg-style.five {
  background: #f3fbe8;
}
