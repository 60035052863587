.own-message {
  text-align: end;
}
.admin-message {
  text-align: start;
}
.message-box {
  padding-right: 14px;
}

.own-message-div h3 {
  background-color: #0084ff;
  display: inline-block;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  padding: 5px;
}
.own-message-div p {
  font-size: 10px;
}

.admin-message-div h3 {
  background-color: #5e60ff;
  display: inline-block;
  color: white;
  border-radius: 7px;
  font-size: 14px;
  padding: 5px;
}
.admin-message-div p {
  font-size: 10px;
}

.own-atchment {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 15px;
}
.admin-atchment {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 6px;
}
