.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center;
}
.login-page {
  min-height: 100%;

  /* background: rgb(222, 236, 248); */
}

.login-page .login-left-col {
  height: calc(100vh - 0.49vh);
}

.login-page .login-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* background: white; */
}

/* register page */

.register-page {
  min-height: 100%;
}
.register-page .register-left-col {
  height: calc(100vh - 0.49vh);
}

.register-page .register-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.register-page .form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
}
.right-top-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
