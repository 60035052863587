.notification-main-box-wrapper::before {
  content: '';
  position: absolute !important;
  top: 35px;
  right: 67%;
  border-style: solid;
  border-radius: 5px !important;
  border-width: 10px 15px 10px 0;
  border-color: transparent var(--main-color) transparent transparent;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  z-index: 9999999;
}

.notification_items {
  padding: 10px 8px 0 8px;
  border-radius: 8px;
  transition: ease-in-out 0.2s;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.notification_items:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #1976d2;
}

.notification-read {
  font-weight: bold;
  color: gray;
  font-size: 13px !important;
}
.notification-unread {
  font-weight: bold;
  color: #0c4da2;
  font-size: 14px !important;
}
