.input-field-and-button {
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  overflow: hidden;
}

.input-field {
  flex: 1;
  height: 40px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
}

.send-button {
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #444;
  font-size: 14px;
  cursor: pointer;
}
.send-button:hover {
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  background-color: #5e60ff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  cursor: pointer;
}

/* messagin section */
.show-message-box {
  height: 340px;
  overflow-y: scroll;
}

.show-message-container {
  padding: 10px 0px 0px 10px;
}
