@media only screen and (max-width: 540px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-right-col {
    padding-top: 0px;
  }

  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }
  .login-wrapper {
    padding-top: 7rem;
  }

  registration .register-page .register-left-col {
    display: none;
  }
  .register-page .register-right-col {
    padding-top: 0px;
  }
  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
  .register-page .form-grid {
    grid-template-columns: 1fr;
  }
  .register-wrapper {
    padding-top: 2rem;
  }
  .sidebar-links .css-5uknkm-MuiTypography-root {
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 414px) and (max-width: 896px) {
  .login-page .login-left-col {
    display: none;
  }
  .login-page .login-right-col {
    text-align: center;
    padding-top: 90px;
  }
  .login-page .login-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .login-page .login-wrapper {
    grid-template-columns: 1fr;
  }
  .login-wrapper {
    padding-top: 7rem;
  }
  register .register-page .register-left-col {
    display: none;
  }

  .register-page .register-right-col .lottie-img {
    text-align: center;
  }
  .register-page .register-right-col .lottie-img > div {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-right-col .lottie-img svg {
    height: 200px !important;
    width: 200px !important;
  }
  .register-page .register-wrapper {
    grid-template-columns: 1fr;
  }
  .register-wrapper {
    padding-top: 2rem;
  }
}

@media only screen and (min-width: 617px) and (max-width: 1367px) {
  .login-page .login-right-col {
    padding-top: 80px !important;
  }
  .register-page .register-right-col {
    height: 100%;
    padding-top: 65px !important;
  }
}

@media only screen and (min-width: 929px) and (max-width: 1920px) {
  .login-page .login-right-col {
    padding-top: 200px;
  }
  .register-page .register-right-col {
    padding-top: 200px;
  }
}
