.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin-top: 0 !important;
}

.css-xi606m {
  text-align: start !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: start !important;
}
.css-1n9caxl {
  background-color: #f7f7f7;
}

.company-photo li {
  list-style: none;
}

/* member from print  */
.member-info-print {
  border: 1px solid gray;
  border-collapse: collapse;
  width: 100%;
}
.id-card-form {
  border-top: none;
}

.member-info-print tbody tr {
  border: 1px solid gray;
  width: 100%;
}
.member-info-print tbody tr td {
  padding-left: 10px;
}
.member-info-print tbody tr th {
  padding-left: 8px;
  height: 20px !important;
}
/* .member-info-print tbody tr td {
  height: 20px !important;
} */
.table-head {
  text-align: start;
  border-right: 1px solid gray;
  width: 30%;
}

.table-middle-head {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  padding-right: 5px;
}
.inbound td {
  width: 50px !important;
}
.table-head-data tr th {
  width: 100% !important;
}

.business-license-table-head th {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  text-align: center;
}
.business-license-table-body td {
  text-align: start;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  text-align: center;
}

.business-license-signature td {
  height: 100px !important;
}
.document-submit thead tr th {
  border-right: 1px solid gray;
}
.document-submit tbody tr td {
  border-right: 1px solid gray;
  padding-right: 5px;
}
